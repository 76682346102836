<!--
 * @Author: your name
 * @Date: 2022-04-28 14:18:06
 * @LastEditTime: 2022-04-28 14:30:20
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\views\rivacy\index.vue
-->
<template>
  <div>
    <h5 class="h4">隐私政策</h5>
    <p>本政策仅适用于河南云锐网络技术有限公司的锐WiFi产品或服务。最近更新日期：2022年4月28日。</p>
    <p>本政策将帮助您了解以下内容：一、我们如何收集和使用您的用户信息
    二、我们如何使用 Cookie 和同类技术
    三、我们如何共享、转让、公开披露您的用户信息
    四、我们如何保护您的用户信息
    五、您的权利
    六、我们如何处理儿童的个人信息
    七、您的用户信息如何储存及如何在全球范围转移
    八、本政策如何更新
    九、如何联系我们</p>
    <p>我们深知用户信息对您的重要性，并会尽全力保护您的用户信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的用户信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的用户信息。
    请在使用我们的产品（或服务）前，仔细阅读并了解本隐私政策。</p>
    <p>一、我们如何收集和使用您的用户信息</p>
    <p>（一）您使用我司产品或服务过程中我们收集和使用的信息
    我们仅会出于本政策所述的业务功能，收集和使用您的用户信息，收集用户信息的目的在于向您提供产品或服务，您有权自行选择是否提供该信息，但多数情况下，如果您不提供，我们可能无法向您提供相应的服务，也无法回应您遇到的问题：
    在您使用我们的服务时，允许我们收集您自行向我们提供的或为向您提供服务所必要的信息包括：手机号码、会员名、订单交易信息、设备信息、服务日志信息等。
    对于我们收集的用户信息，我们会为您提供注册和账户管理服务、向您展示商品和服务、为您提供购买功能、帮助您完成下单及订单管理、帮助您完成支付、帮助向您完成商品或服务的交付等。
    您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您停止使用推送服务时，我们将停止使用并删除上述信息。
    我们保证会依法对收集后的用户信息进行去标识化或匿名化处理，对于无法单独或者与其他信息结合识别自然人个人身份的信息，不属于法律意义上的个人信息。如果我们将非个人信息与其他信息结合识别到您的个人身份时，或者与您的个人信息结合使用时，我们会在结合使用期间，将此类信息作为您的个人信息按照本隐私政策对其进行处理和保护。
    为了更好运营和改善我们的技术和服务，或出于商业策略的变化，当我们提供的产品或服务所需的用户信息收集、使用、处理超出上述范围或者要将收集到的用户信息用于本隐私政策未载明的其他用途，或当我们要将基于特定目的收集而来的信息用于其他目的时，我们会在获取用户信息后的合理期限内或处理用户信息前通知您，并获得您的授权同意。
    </p>
    <p>（二）征得授权同意的例外</p>
    <p>请您理解，根据法律法规及相关国家标准，以下情形中，我们收集和使用您的用户信息无须征得您的授权同意：</p>
    <p>1、与国家安全、国防安全直接相关的；</p>
    <p>2、与公共安全、公共卫生、重大公共利益直接相关的； </p>
    <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的； </p>
    <p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； </p>
    <p>5、所收集的您的用户信息是您自行向社会公众公开的；</p>
    <p>6、从合法公开披露的信息中收集的您的用户信息，如合法的新闻报道、政府信息公开等渠道； </p>
    <p>7、根据您的要求签订或履行合同所必需的； </p>
    <p>8、用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；</p>
    <p>9、个人信息控制者为新闻单位且其在开展合法的新闻报道所必需的； </p>
    <p>10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</p>
    <p>11、法律法规规定的其他情形。</p>
    <p>（三）应用权限获取与说明</p>
    <p>1、读写手机存储权限：应用会将一些必要的信息和缓存数据保存到您的手机中，从而提高应用的浏览和加载速度。另外，当您需要上传或存储应用中的图片、文本等数据时，应用需要该权限来获取您提供的文件数据。如果拒绝该权限，可能会在一定程度上影响您的使用体验。</p>
    <p>2、相机权限：允许应用拍摄照片和视频。如果拒绝该权限，您将无法通过该应用拍摄照片和视频。</p>
    <p>二、我们如何使用 Cookie 和同类技术</p>
    <p>（一）Cookie
    为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储您的访问偏好数据。
    我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。</p>
    <p>（二）网站信标和像素标签
    除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。
    如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</p>
    <p>（三）Do Not Track（请勿追踪）</p>
    <p>很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do Not Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们的所有网站都会尊重您的选择。</p>
    <p>三、我们如何共享、转让、公开披露您的用户信息</p>
    <p>我们不会与其他的任何公司、组织和个人分享您的用户信息，但以下情况除外：</p>
    <p>1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息。</p>
    <p>2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的用户信息。</p>
    <p>3、与我们的关联公司共享：您的用户信息可能会与我们的关联公司共享。我们只会共享必要的用户信息，且受本隐私政策中所声明目的的约束。关联公司如要改变用户信息的处理目的，将再次征求您的授权同意。</p>
    <p>4、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些用户信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。为了更好运营和改善技术和服务，您同意我们和授权合作伙伴在符合相关法律法规的前提下可将收集的信息用于其他服务和用途。
    以下列举了具体的授权合作伙伴，并提供了该第三方的隐私政策链接，我们建议您阅读该第三方的隐私政策：</p>
    <p>极光推送SDK：用于实现消息推送（或其他推送）功能。我们可能会收集您的设备信息、地理位置信息、网络信息，用于为您提供推送技术服务。其中设备信息包含设备标识符（IMEI、IDFA、AndroidID、MAC、OAID、UAID等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息），网络信息包含IP地址、WiFi信息、基站信息等相关信息。隐私政策详细内容请访问《极光隐私政策》。</p>
    <p>极光认证SDK：用于号码认证服务。收集个人信息类型：设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI信息等）：用于识别唯一用户，保证消息认证的精准送达；优化认证通道资源，我们会根据设备上不同APP的活跃情况，整合消息认证的通道资源，为开发者提高消息送达率；为开发者提供智能标签以及展示业务统计信息的服务；网络信息与位置信息（IP地址，WiFi信息，基站信息等相关信息）：优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性；实现区域认证的功能。APP安装列表信息：我们为向您提供智能认证功能，通过该信息推荐更符合您用户需要的认证内容，减少无用认证信息对您用户的打扰，您可以选择开启或关闭这项服务。隐私政策详细内容请访问《极光隐私政策》。</p>
    <p>极光统计SDK：用于消息统计服务。设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI信息等）：用于识别唯一用户，保证消息的精准统计；为开发者提供业务统计信息的服务；网络信息与位置信息（IP地址，WiFi信息，基站信息等相关信息）：优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性；实现区域统计的功能。APP安装列表信息：我们为向您提供统计功能，通过该信息推荐更符合您用户需要的统计内容，减少无用统计信息对您用户的打扰，您可以选择开启或关闭这项服务。隐私政策详细内容请访问《极光隐私政策》。</p>
    <p>对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。</p>
    <p>（二）转让</p>
    <p>我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <p>1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的用户信息；</p>
    <p>2、在涉及合并、收购或破产清算时，如涉及到用户信息转让，我们会再要求新的持有您用户信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
    <p>（三）公开披露</p>
    <p>我们仅会在以下情况下，公开披露您的用户信息：</p>
    <p>1、获得您明确同意后；</p>
    <p>2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的用户信息。</p>
    <p>（四）共享、转让、公开披露信息时事先征得授权同意的例外</p>
    <p>请您理解，根据法律法规及相关国家标准，以下情形中，我们共享、转让、公开披露您的用户信息无须征得您的授权同意：
    1、与国家安全、国防安全直接相关的； 
    2、与公共安全、公共卫生、重大公共利益直接相关的； 
    3、与犯罪侦查、起诉、审判和判决执行等直接相关的； 
    4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； 
    5、您自行向社会公众公开的信息； 
    6、从合法公开披露的信息中收集的，如合法的新闻报道、政府信息公开等渠道</p>
    <p>四、我们如何保护您的用户信息</p>
    <p>（一）我们已使用符合业界标准的安全防护措施保护您提供的用户信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的用户信息。例如，在您的浏览器与“服务”之间交换数据时受 SSL 加密保护；我们同时对网站提供https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。</p>
    <p>（二）我们会采取一切合理可行的措施，确保未收集无关的用户信息。我们只会在达成本政策所述目的所需的期限内保留您的用户信息，除非需要延长保存期或受到法律的允许。</p>
    <p>（三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方</p>
    <p>（四）我们将定期更新并公开安全风险、用户信息安全影响评估等报告的有关内容。</p>
    <p>（五）互联网环境并非百分之百安全，我们将尽力确保您发送给我们的任何信息的安全性。即使我们做出了很大努力，采取了一切合理且必要的措施，仍然有可能无法杜绝您的用户信息被非法访问、被非法盗取，被非法篡改或毁坏，导致您的合法权益受损，请您理解信息网络的上述风险并自愿承担。</p>
    <p>（六）在不幸发生用户信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报用户信息安全事件的处置情况。</p>
    <p>五、您的权利</p>
    <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的用户信息行使以下权利：</p>
    <p>（一）访问您的用户信息
    您有权访问您的用户信息，法律法规规定的例外情况除外。
    </p>
    <p>（二）更正您的用户信息
    当您发现我们处理的关于您的用户信息有错误时，您有权要求我们作出更正。您可以随时使用我们的 Web 表单联系。我们将在30天内回复您的更正请求。</p>
    <p>（三）删除您的用户信息
    在以下情形中，您可以向我们提出删除用户信息的请求：
    1、如果我们处理用户信息的行为违反法律法规；
    2、如果我们收集、使用您的用户信息，却未征得您的同意；
    3、如果我们处理用户信息的行为违反了与您的约定；
    4、如果您不再使用我们的产品或服务，或您注销了账号；
    5、如果我们不再为您提供产品或服务。
    我们将会根据您的删除请求进行评估，若满足相应规定，我们将会采取相应步骤进行处理。当您向我们提出删除请求时，我们可能会要求您进行身份验证，以保障账户的安全。当您从我们的服务中删除信息后，因为适用的法律和安全技术，我们可能不会立即从备份系统中删除相应的信息，我们将安全存储您的信息直到备份可以清除或实现匿名化。</p>
    <p>（四）改变您授权同意的范围</p>
    <p>每个业务功能需要一些基本的用户信息才能得以完成（见本政策“第一部分”）。对于用户信息的收集和使用，您可以随时给予或收回您的授权同意。
    当您收回同意后，我们将不再处理相应的用户信息。同时也请您注意，您撤销授权同意可能会导致某些后果，例如我们可能无法继续为您提供相应的服务或特定的功能，但您收回同意的决定，不会影响此前基于您的授权而开展的用户信息处理。</p>
    <p>（五）用户信息主体注销账户 </p>
    <p>您可随时注销此前注册的账户，您可以联系客服进行相关操作。
    在注销账户之后，我们将停止为您提供产品或服务并依据您的要求，删除或匿名化您的信息，法律法规另有规定的除外。这也将可能导致您失去对您账户中数据的访问权，请您谨慎操作。</p>
    <p>（六）约束信息系统自动决策</p>
    <p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。</p>
    <p>（七）响应您的上述请求</p>
    <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
    我们将在三十天内作出答复。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。也请您理解，出于安全保障的考虑、相关法律法规的要求或技术上的限制，对于您的某些请求我们可能无法做出响应，例如以下情形：
    1、与用户信息控制者履行法律法规规定的义务相关的；
    2、与国家安全、国防安全直接相关的；
    3、与公共安全、公共卫生、重大公共利益直接相关的；
    4、与犯罪侦查、起诉、审判和执行判决等直接相关的；
    5、用户信息控制者有充分证据表明用户信息主体存在主观恶意或滥用权利的；
    6、出于维护用户信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    7、响应用户信息主体的请求将导致用户信息主体或其他个人、组织的合法权益受到严重损害的； 
    8、涉及商业秘密的。</p>
    <p>六、我们如何处理儿童的个人信息</p>
    <p>我们非常重视儿童个人信息的保护，我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不应创建自己的用户账户。尽管当地法律和习俗对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。
    对于经父母或监护人同意而收集儿童用户信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下储存、使用或公开披露此信息，否则我们会设法尽快删除相关数据。
    鉴于现有技术和业务模式的限制，我们很难主动识别儿童的个人信息，如果您发现我们在不知情的情况下或在未事先获得可证实的监护人同意的情况下收集了儿童的个人信息，您可以及时联系我们，我们将在发现后设法及时删除，如果我们自己发现前述情形的，我们也会及时删除，法律要求我们保留的除外。</p>
    <p>七、您的用户信息如何储存以及如何在全球范围转移
    原则上，我们在中华人民共和国境内收集和产生的用户信息，将存储在中华人民共和国境内。我们只会在本政策所述目的和用途所需的期限内和法律法规规定的最短期限内保留您的用户信息，超出上述保留期间后，我们会根据适用法律法规的要求删除您的用户信息或匿名化处理。法律法规另有规定，或出于公共利益、科学历史研究等的目的，或您的另行授权同意的除外，我们可能需要较长时间保留相关数据。</p>
    <p>八、本政策如何更新
    我们的隐私政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
    对于重大变更，我们还会提供更为显著的通知。本政策所指的重大变更包括但不限于：
    1、我们的服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；
    2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    3、用户信息共享、转让或公开披露的主要对象发生变化；
    4、您参与用户信息处理方面的权利及其行使方式发生重大变化；
    5、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；
    6、用户信息安全影响评估报告表明存在高风险时。
    我们还会将本政策的旧版本存档，供您查阅。</p>
    <p>九、如何联系我们</p>
    <p>如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</p>
    <p>电　　话：400-960-7995</p>
  </div>
</template>
<script>
export default {
  data() {
    return{

    }
  },
}
</script>
<style lang="less" scoped>
.h4{
  text-align: center;
  font-size: 14px;
  padding-top: 20px;
  margin-bottom: 10px;
}
</style>